<template>
  <div class="stationList">
    <div v-show="!isSearch">
      <div class="header">
        <div :style="{ height: appBarHeight, backgroundColor: 'white' }"></div>
        <div class="navBar">
          <img
            @click="handleBack"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back_icon.png"
            alt=""
          />
          <span>站点管理</span>
        </div>
        <div class="searchBar" @click="isSearch = true">
          <span v-if="parkInfo && parkInfo.parkCode" class="filled">{{
            parkInfo.parkName
          }}</span>
          <span v-else>全部网格</span>
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/arrow-down.png"
            alt=""
          />
        </div>
      </div>
      <div
        class="listWrapper"
        :style="{ paddingTop: `calc(2.36rem + ${appBarHeight})` }"
      >
        <van-list v-if="!noData">
          <van-swipe-cell
            v-for="(item, index) in list"
            :key="item.stationId"
            :name="index"
            :ref="'ref' + index"
            @open="open"
          >
            <div class="listItem">
              <div class="stationName">{{ item.stationName }}</div>
              <div class="parkName">{{ item.parkName || '暂无网格信息' }}</div>
            </div>
            <template #right>
              <div class="deleteBtn" @click="handleDeleteClick(item)">删除</div>
            </template>
          </van-swipe-cell>
        </van-list>
        <div class="noData" v-else>
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
            alt=""
          />
          <span>暂无数据</span>
        </div>
        <infinite-loading
          v-if="list.length"
          direction="bottom"
          @infinite="infiniteHandler"
        >
          <div class="more" slot="no-more">没有更多了</div>
          <div class="more" slot="no-results">没有更多了</div>
        </infinite-loading>
      </div>
    </div>
    <div v-if="isSearch">
      <SearchPanel
        :appBarHeight="appBarHeight"
        @onCancel="handleSearchCancel"
        @onSelect="handleSearchSelect"
      ></SearchPanel>
    </div>
    <Dialog
      v-if="showDialog"
      dialogContent="确认删除该站点吗？"
      @handleOk="handleDialogOk"
      @handleCancel="handleDialogCancel"
    >
    </Dialog>
  </div>
</template>

<script>
import { getStationListByParkCode } from '@/api/api';
import { deletePoi } from '@/api/apiv2';
import SearchPanel from '@/components/SearchPanel.vue';
import Dialog from '@/components/Dialog.vue';

export default {
  components: { SearchPanel, Dialog },

  data() {
    return {
      appBarHeight: `${
        (sessionStorage.getItem('_appBarHeight') ?? 0) / 100
      }rem`,
      noData: false,
      currentOpen: null,
      isSearch: false,
      value: '',
      list: [],
      parkInfo: null,
      total: 0,
      pageNum: 1,
      showDialog: false,
      currentStation: null, // 删除时当前选中的站点
      // loading: false,
      finished: false,
    };
  },
  created() {
    const { parkCode, parkName } = this.$route.query;
    if (parkCode && parkName) {
      this.parkInfo = { parkCode, parkName };
    }
  },
  mounted() {
    this.getStationList(this.parkInfo?.parkCode);
  },

  methods: {
    handleBack() {
      this.$router.push({
        path: '/stationManagement',
        query: { from: 'list' },
      });
    },
    handleSearchCancel() {
      this.isSearch = false;
    },
    handleSearchSelect(item) {
      this.parkInfo = item;
      this.isSearch = false;
      this.noData = false;
      this.list = [];
      this.total = 0;
      this.pageNum = 1;
      this.getStationList(item.parkCode);
    },
    getStationList(parkCode, pageNum = 1, pageSize = 20) {
      this.$loading.start();
      getStationListByParkCode({ pageSize, pageNum, parkCode })
        .then((res) => {
          if (res?.data) {
            this.list = [...this.list, ...res.data];
            this.total = res.totalCount;
            this.pageNum = res.pageNo;
            if (this.list.length === 0) {
              this.noData = true;
            }
          }
        })
        .finally(() => {
          this.$loading.end();
        });
    },
    handleDeleteClick(item) {
      this.currentStation = item;
      this.showDialog = true;
    },
    // 删除站点并重新更新
    async deleteStation() {
      this.$loading.start();
      // this.currentOpen = null;
      // await deleteById({ stationId: this.currentStation.stationId });

      const res = await deletePoi({ id: this.currentStation.stationId }).catch(
        (error) => {
          console.log(error);
        }
      );
      if (res) {
        const newStationList = await getStationListByParkCode({
          pageSize: 20 * this.pageNum,
          pageNum: 1,
          parkCode: this.parkInfo?.parkCode,
        });
        this.list = newStationList.data;
        if (this.list.length === 0) {
          this.noData = true;
        }
      }
      this.$loading.end();
    },
    handleDialogOk() {
      this.showDialog = false;
      this.deleteStation();
    },
    handleDialogCancel() {
      this.showDialog = false;
    },
    infiniteHandler(state) {
      if (this.loading) {
        state.loaded();
        return;
      }
      this.loading = true;
      const parkCode = this.parkInfo?.parkCode;
      getStationListByParkCode({
        pageSize: 20,
        pageNum: this.pageNum + 1,
        parkCode,
      })
        .then((res) => {
          if (res?.data) {
            if (this.total === this.list.length) {
              state.complete();
              return;
            } else {
              state.loaded();
            }
            this.list = [...this.list, ...res.data];
            this.total = res.totalCount;
            this.pageNum = res.pageNo;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    open(e) {
      if (this.currentOpen && this.currentOpen._props.name !== e.name) {
        // 关闭上一个cell,连续滑动同一个cell时不用调close
        this.currentOpen.close();
      }
      this.currentOpen = this.$refs['ref' + e.name][0];
    },
  },
};
</script>

<style scoped lang="scss">
.stationList {
  position: relative;
  background: #f6f7f8;
  height: 100vh;
  overflow: auto;

  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: #f6f7f8;

    .navBar {
      height: 0.88rem;
      line-height: 0.88rem;
      position: relative;
      font-size: 0.3rem;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
      background: white;

      img {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.07rem;
        left: 0.24rem;
      }
    }

    .searchBar {
      background: #ffffff;
      width: 7.18rem;
      height: 0.8rem;
      margin: 0.32rem auto;
      box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.16);
      border-radius: 0.08rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.3rem;
      color: #bfc0cb;
      box-sizing: border-box;
      padding: 0 0.24rem;

      span {
        &.filled {
          font-size: 0.3rem;
          color: #000000;
          font-weight: 500;
        }
      }

      img {
        width: 0.32rem;
        height: 0.32rem;
      }
    }
  }

  .listWrapper {
    // padding-top: 2.36rem;

    // .list {
    .listItem {
      pointer-events: none;
      user-select: none;
      height: 1.16rem;
      margin: 0 0.4rem;
      border-bottom: 1px solid #ebebeb;
      padding: 0.16rem 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .stationName {
        width: 7.1rem;
        font-size: 0.32rem;
        color: #000000;
        letter-spacing: 0;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.08rem;
      }

      .parkName {
        width: 7.1rem;
        font-size: 0.28rem;
        color: #afafaf;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .deleteBtn {
      font-size: 0.36rem;
      line-height: 1.16rem;
      color: #ffffff;
      width: 1.55rem;
      height: 1.16rem;
      background: #e76a49;
      text-align: center;
    }

    // }

    .noData {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 2.4rem;

      img {
        width: 3.2rem;
        height: 3.2rem;
      }

      span {
        font-size: 0.32rem;
        color: #222222;
      }
    }

    .more {
      margin: 0.6rem 0 0.24rem;
      text-align: center;
      font-size: 0.28rem;
      color: #9798ab;
    }
  }
}
</style>
